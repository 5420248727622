import React from 'react'

const Footer = () => (
  <div className="footer">
    <div className="footer-content has-text-centered">
      <h3 className="footer-title">Contact Us</h3>
      <p>
        <a className="has-text-dark" href="mailto:help@encounterdating.com.au">
          help@encounterdating.com.au
        </a>
      </p>
      <p className="has-text-dark">SMS Support: <a className="has-text-dark" href='tel:0444555857'>0444 555 857</a></p>
      <div className="divider-line"></div>
      <div className="bottom-section">
        <p className="copyright">&copy; {(new Date).getFullYear()} Encounter Dating - All rights reserved.</p>
        <div className="links-container">
          <a
            className="has-text-dark links"
            href="https://www.encounterdating.com.au/privacy-policy"
            target="_self"
          >
            Privacy Policy
          </a>
          <a
            className="has-text-dark links"
            href="https://www.encounterdating.com.au/terms-conditions"
            target="_self"
          >
            Terms &amp; Conditions
          </a>
        </div>
      </div>
    </div>
  </div>
)

export default Footer
